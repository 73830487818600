import { useRef, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartOptions,
  TimeSeriesScale,
} from 'chart.js'
import { Grid, Typography } from '@mui/material'
import { Line, getElementAtEvent } from 'react-chartjs-2'
import { deepPurple, deepOrange } from '@mui/material/colors'
import dayjs, { Dayjs } from 'dayjs'
import 'chartjs-adapter-luxon'

import { useFetchStressLevelsOverTime } from '@/hooks/api'
import Loader from './loader'
import ErrorMessage from './errorMessage'
import DayViewDetails from './dayViewDetails'
import ReportDetails from './reportDetails'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  TimeSeriesScale,
)

interface StressOverTimeProps {
  patientId: string
  startDate: Date
  endDate: Date
  startDateAlt?: Date
  endDateAlt?: Date
}

const StressOverTime = ({
  patientId,
  startDate,
  endDate,
  startDateAlt = new Date(),
  endDateAlt = new Date(),
}: StressOverTimeProps) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs>()
  const chartRef = useRef()
  const {
    loading,
    data: results,
    error,
  } = useFetchStressLevelsOverTime(patientId, startDate, endDate, [
    startDate,
    endDate,
  ])
  const {
    loading: loadingAlt,
    data: resultsAlt,
    error: errorAlt,
  } = useFetchStressLevelsOverTime(patientId, startDateAlt, endDateAlt, [
    startDateAlt,
    endDateAlt,
  ])

  const stressPoints = results?.items || []
  const stressPointsAlt = resultsAlt?.items || []

  const onPointClicked = (event: any) => {
    if (chartRef.current) {
      const { index: xIndex, datasetIndex } =
        getElementAtEvent(chartRef.current, event).pop() || {}
      if ((xIndex || xIndex === 0) && (datasetIndex || datasetIndex === 0)) {
        const chosenDate =
          chartData.datasets[datasetIndex].data[xIndex].x || startDate
        setSelectedDate(dayjs(chosenDate))
      }
    }
  }

  const options: ChartOptions<'line'> = {
    onHover: function (event: any) {
      const points = (this as unknown as ChartJS).getElementsAtEventForMode(
        event,
        `index`,
        { axis: `x`, intersect: true },
        false,
      )

      if (points.length) event.native.target.style.cursor = `pointer`
      else event.native.target.style.cursor = `default`
    },
    responsive: true,
    interaction: {
      mode: `index` as const,
      intersect: false,
    },
    animation: {
      duration: 0,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          align: `center`,
          text: `Spanning daggemiddelde`,
          font: { size: 16, weight: `bold` },
        },
        grace: 1,
        max: 6,
        min: 0,
      },
      x: {
        type: `time`,
        time: {
          unit: `day`,
          displayFormats: {
            day: `DD`,
          },
        },
        title: {
          display: true,
          align: `center`,
          text: `Datum`,
          font: { size: 16, weight: `bold` },
        },
        adapters: {
          date: {
            locale: `nl`,
          },
        },
      },
    },
  }

  const chartData = {
    datasets: [
      {
        data: stressPoints.map((point) => ({
          x: new Date(point.day),
          y: point.value,
        })),
        borderWidth: 2,
        borderColor: deepPurple[300],
        backgroundColor: `#fff`,
        pointBorderWidth: 4,
        hoverBorderWidth: 10,
        tension: 0.15,
      },
      {
        data: stressPointsAlt.map((point) => ({
          x: new Date(point.day),
          y: point.value,
        })),
        borderWidth: 4,
        borderColor: deepOrange[500],
        backgroundColor: `#fff`,
        pointBorderWidth: 4,
        hoverBorderWidth: 10,
        pointStyle: `rect`,
        tension: 0.15,
      },
    ],
  }

  if (loading || loadingAlt) {
    return <Loader size={`sm`} />
  }

  if (error || errorAlt) {
    return (
      <ErrorMessage
        message={
          (error as string) || `There was an error while fetching chart data`
        }
      />
    )
  }

  return (
    <Grid container spacing={0.5}>
      <Grid item xs={12}>
        <Line
          options={options}
          data={chartData}
          ref={chartRef}
          onClick={onPointClicked}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          sx={{ minHeight: `100px`, marginTop: 4, marginBottom: 4 }}
        >
          {selectedDate ? (
            <>
              <Grid item md={6}>
                <Typography sx={{ marginLeft: 2 }}>
                  Gedetailleerde gegevens
                </Typography>
                <DayViewDetails
                  patientId={patientId}
                  selectedDate={selectedDate}
                />
              </Grid>
              <Grid item md={6}>
                <ReportDetails
                  patientId={patientId}
                  selectedDate={selectedDate}
                />
              </Grid>
            </>
          ) : (
            <Grid item md={12}>
              <Typography sx={{ textAlign: `center` }}>
                Selecteer een dag om een overzicht te tonen
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default StressOverTime
