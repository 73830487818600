import { purple, deepPurple, orange, deepOrange } from '@mui/material/colors'

export const occurencesColors: { [key: number]: string } = {
  1: orange[100],
  2: orange[300],
  3: deepOrange[500],
  4: deepOrange[900],
  5: purple[100],
  6: purple[300],
  7: deepPurple[500],
  8: deepPurple[900],
}
