import { Grid, Typography } from '@mui/material'
import styled from '@emotion/styled'

import { occurencesColors } from '../utils/globals'

interface ChartLegendProps {
  occurences: number
}

const ChartLegend = ({ occurences }: ChartLegendProps) => {
  const LegendTitle = styled(Typography)(() => ({
    marginBottom: `18px`,
  }))

  const LegendRect = styled(Grid)(() => ({
    height: `24px`,
    margin: `12px 0`,
  }))

  const LegendLabel = styled(Typography)(() => ({
    marginLeft: `16px`,
  }))

  return (
    <Grid container justifyContent="center">
      <LegendTitle variant="subtitle1">Occurences</LegendTitle>
      {[...Array(occurences)].map((_, index) => (
        <Grid key={index} container justifyContent="center" alignItems="center">
          <LegendRect
            item
            xs={5}
            sx={{ backgroundColor: occurencesColors[index + 1] }}
          />
          <LegendLabel>{index + 1}</LegendLabel>
        </Grid>
      ))}
    </Grid>
  )
}

export default ChartLegend
