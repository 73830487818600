import { StressRanges } from './models'

export const findStressLevels = (
  ranges: StressRanges,
  comparedValueOne: number,
  comparedValueTwo?: number,
) => {
  const barsData = Object.values(ranges).map(
    (
      hourlySpan: Array<{
        stressLevel: number
        count: number
      }>,
    ) => {
      const numberOfMeasurements = hourlySpan.reduce((acc: number, cur) => {
        return acc + cur.count
      }, 0)

      return hourlySpan
        .map(({ stressLevel, count }) => {
          if (
            stressLevel === comparedValueOne ||
            stressLevel === comparedValueTwo
          ) {
            return (count / numberOfMeasurements) * 100
          } else {
            return 0
          }
        })
        .reduce((acc, val) => acc + val, 0)
    },
  )

  return barsData
}

export const truncateString = (str: string, num: number) => {
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + `...`
}
