import { useRef, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartOptions,
  TimeSeriesScale,
} from 'chart.js'
import { Grid, Typography } from '@mui/material'
import { Line, getElementAtEvent } from 'react-chartjs-2'
import { deepPurple, deepOrange } from '@mui/material/colors'
import dayjs, { Dayjs } from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import 'chartjs-adapter-luxon'

import { useFetchStressLevelsOverTime } from '@/hooks/api'
import Loader from './loader'
import ErrorMessage from './errorMessage'
import DayViewDetails from './dayViewDetails'
import { StressMeasurement } from '@/utils/models'

dayjs.extend(weekOfYear)

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  TimeSeriesScale,
)

interface ComparisonChartProps {
  patientId: string
  startDate: Date
  endDate: Date
  startDateAlt: Date
  endDateAlt: Date
}

const ComparisonChart = ({
  patientId,
  startDate,
  endDate,
  startDateAlt,
  endDateAlt,
}: ComparisonChartProps) => {
  const [selectedDates, setSelectedDates] = useState<Array<Dayjs>>([
    dayjs(startDate),
  ])
  const chartRef = useRef()
  const {
    loading,
    data: results,
    error,
  } = useFetchStressLevelsOverTime(patientId, startDate, endDate, [
    startDate,
    endDate,
  ])
  const {
    loading: loadingAlt,
    data: resultsAlt,
    error: errorAlt,
  } = useFetchStressLevelsOverTime(patientId, startDateAlt, endDateAlt, [
    startDateAlt,
    endDateAlt,
  ])

  const generateStressPoints = (data?: Array<StressMeasurement>) => {
    if (!data) {
      return []
    }
    const sortedWeeks = data
      ?.map((day) => {
        return {
          x: dayjs(day.day).week(),
          y: day.value,
          day: day.day,
        }
      })
      .sort((a, b) => a.x - b.x)

    const averageSressPoints = Array.from(
      new Set(sortedWeeks?.map((s) => s.x)),
    ).map((lab) => {
      const sameDates = sortedWeeks?.filter((s) => s.x === lab)
      return {
        x: lab,
        y: sameDates.reduce((a, b) => a + b.y, 0) / sameDates.length,
        day: sameDates.map((day) => day.day),
      }
    })

    return averageSressPoints
  }

  const stressPoints = generateStressPoints(results?.items)
  const stressPointsAlt = generateStressPoints(resultsAlt?.items)

  const onPointClicked = (event: any) => {
    if (chartRef.current) {
      const { index: xIndex, datasetIndex } =
        getElementAtEvent(chartRef.current, event).pop() || {}
      if ((xIndex || xIndex === 0) && (datasetIndex || datasetIndex === 0)) {
        const chosenDates = chartData.datasets[datasetIndex].data[xIndex]
          .day || [startDate]
        setSelectedDates(chosenDates.map((day) => dayjs(day)))
      }
    }
  }

  const options: ChartOptions<'line'> = {
    onHover: function (event: any) {
      const points = (this as unknown as ChartJS).getElementsAtEventForMode(
        event,
        `index`,
        { axis: `x`, intersect: true },
        false,
      )

      if (points.length) event.native.target.style.cursor = `pointer`
      else event.native.target.style.cursor = `default`
    },
    responsive: true,
    interaction: {
      mode: `index` as const,
      intersect: false,
    },
    animation: {
      duration: 0,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          align: `center`,
          text: `Spanning daggemiddelde`,
          font: { size: 16, weight: `bold` },
        },
        grace: 1,
        max: 6,
        min: 0,
      },
      x: {
        title: {
          display: true,
          align: `center`,
          text: `Week`,
          font: { size: 16, weight: `bold` },
        },
        grace: 1,
      },
    },
  }

  const chartData = {
    labels: [1, 2, 3, 4, 5, 6, 7, 8],
    datasets: [
      {
        data: stressPoints,
        borderWidth: 2,
        borderColor: deepPurple[300],
        backgroundColor: `#fff`,
        pointBorderWidth: 4,
        hoverBorderWidth: 10,
        tension: 0.15,
      },
      {
        data: stressPointsAlt,
        borderWidth: 4,
        borderColor: deepOrange[500],
        backgroundColor: `#fff`,
        pointBorderWidth: 4,
        hoverBorderWidth: 10,
        pointStyle: `rect`,
        tension: 0.15,
      },
    ],
  }

  if (loading || loadingAlt) {
    return <Loader size={`sm`} />
  }

  if (error || errorAlt) {
    return (
      <ErrorMessage
        message={
          (error as string) || `There was an error while fetching chart data`
        }
      />
    )
  }

  return (
    <Grid container spacing={0.5}>
      <Grid item xs={12} sm={9}>
        <Line
          options={options}
          data={chartData}
          ref={chartRef}
          onClick={onPointClicked}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Typography sx={{ marginLeft: 2 }}>Gedetailleerde gegevens</Typography>
        {selectedDates.map((day, id) => (
          <DayViewDetails key={id} patientId={patientId} selectedDate={day} />
        ))}
      </Grid>
    </Grid>
  )
}

export default ComparisonChart
