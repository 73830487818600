import type { PageProps } from 'gatsby'
import { Router } from '@reach/router'

import Layout from '@/components/layout'
import PrivateRoute from '@/components/privateRoute'
import InsightPage from '@/components/insight'

const App = (props: PageProps) => {
  return (
    <Layout {...props}>
      <Router basepath="/">
        <PrivateRoute path="/insight/:userId" component={InsightPage} />
      </Router>
    </Layout>
  )
}
export default App
