import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
} from 'chart.js'
import Grid from '@mui/material/Grid'
import { Bar } from 'react-chartjs-2'

import { useFetchStressParDayOfTheWeek } from '@/hooks/api'
import Loader from './loader'
import ErrorMessage from './errorMessage'
import ChartLegend from './chartLegend'
import { occurencesColors } from '../utils/globals'

ChartJS.register(CategoryScale, LinearScale, BarElement)

interface StressPerDayOfTheWeekProps {
  patientId: string
  startDate: Date
}

const StressPerDayOfTheWeek = ({
  patientId,
  startDate,
}: StressPerDayOfTheWeekProps) => {
  const {
    loading,
    data: results,
    error,
  } = useFetchStressParDayOfTheWeek(patientId, startDate)
  const stressPoints = results || null
  const options = {
    responsive: true,
    interaction: {
      mode: `index` as const,
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        display: true,
        text: `Gemiddeld spanningsniveau per dag`,
        font: { size: 16, weight: `bold` },
        padding: 20,
      },
    },
    scales: {
      y: {
        grace: 1,
        max: 6,
        min: 1,
      },
      x: {
        stacked: true,
        grace: 0,
        max: 6,
        min: 0,
        grid: {
          display: false,
        },
      },
    },
  }

  const occurences: { [key: string | number]: [] } =
    stressPoints?.results.reduce(
      (occurrencesObject: { [key: number]: any }, result) => {
        const maxLength = Math.max(
          ...result.daysOfWeek.map((day) => day.length - 1),
        )
        const occurancesDatasets = []
        for (let i = maxLength; i >= 0; i--) {
          const dailyResults = result.daysOfWeek.map(
            (day) => (day[i] && stressPoints?.ranges[day[i].rangeIndex]) || 0,
          )
          occurancesDatasets.push({
            data: dailyResults,
            backgroundColor: occurencesColors[result.occurrences],
          })
        }

        occurrencesObject[result.occurrences] = occurancesDatasets
        return occurrencesObject
      },
      {},
    ) || {}

  const chartData = {
    labels: [`Ma`, `Di`, `Woe`, `Do`, `Wrij`, `Za`, `Za`],
    datasets: Object.keys(occurences).flatMap((key) => {
      // key is the occurance number
      return occurences[key].map((occurence) => occurence)
    }),
  }

  if (loading) {
    return <Loader size={`sm`} />
  }

  if (error) {
    return (
      <ErrorMessage
        message={
          (error as string) || `There was an error while fetching chart data`
        }
      />
    )
  }

  return (
    <Grid container spacing={0.5}>
      <Grid item xs={12} sm={9}>
        <Bar options={options} data={chartData} />
      </Grid>
      <Grid item xs={12} sm={3}>
        <ChartLegend
          occurences={
            Object.values(occurences).filter((array) => array.length).length
          }
        />
      </Grid>
    </Grid>
  )
}

export default StressPerDayOfTheWeek
