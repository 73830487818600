import { useState, Dispatch, SetStateAction } from 'react'
import { Typography, Grid } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import styled from '@emotion/styled'

import Navigator from '@/components/navigator'
import ComparisonChart from '@/components/comparisonChart'

interface ComparisonProps {
  patientId: string
  initialStartDate: Dayjs
  initialEndDate: Dayjs
}

const StyledHeading = styled(Typography)(() => ({
  fontWeight: 700,
  marginBottom: 25,
}))

dayjs.Ls.en.weekStart = 1
const Comparison = ({
  patientId,
  initialStartDate,
  initialEndDate,
}: ComparisonProps) => {
  // Date one
  const [startDateOne, setStartDateOne] = useState(initialStartDate)
  const [endDateOne, setEndDateOne] = useState(initialEndDate)
  const [dateOne, setDateOne] = useState(
    `${startDateOne.format(`D MMMM YYYY`)} - ${endDateOne.format(
      `D MMMM YYYY`,
    )}`,
  )
  // Date two
  const [startDateTwo, setStartDateTwo] = useState(
    dayjs().subtract(8, `weeks`).startOf(`week`),
  )
  const [endDateTwo, setEndDateTwo] = useState(dayjs().endOf(`week`))
  const [dateTwo, setDateTwo] = useState(
    `${startDateTwo.format(`D MMMM YYYY`)} - ${endDateTwo.format(
      `D MMMM YYYY`,
    )}`,
  )

  const setFormattedDate = (
    startDate: Dayjs,
    endDate: Dayjs,
    setMethod: Dispatch<SetStateAction<string>>,
  ) => {
    setMethod(
      `${startDate.format(`D MMMM YYYY`)} - ${endDate.format(`D MMMM YYYY`)}`,
    )
  }

  const prevOne = () => {
    let newStartDate = startDateOne.subtract(1, `weeks`).startOf(`week`)
    let newEndDate = endDateOne.subtract(1, `weeks`).endOf(`week`)

    if (newStartDate.isSame(startDateTwo)) {
      newStartDate = startDateOne.subtract(2, `weeks`).startOf(`week`)
    }
    if (newEndDate.isSame(endDateTwo)) {
      newEndDate = endDateOne.subtract(2, `weeks`).endOf(`week`)
    }
    setStartDateOne(newStartDate)
    setEndDateOne(newEndDate)
    setFormattedDate(newStartDate, newEndDate, setDateOne)
  }

  const nextOne = () => {
    let newStartDate = startDateOne.add(1, `weeks`).startOf(`week`)
    let newEndDate = endDateOne.add(1, `weeks`).endOf(`week`)

    if (newStartDate.isSame(startDateTwo)) {
      newStartDate = startDateOne.add(2, `weeks`).startOf(`week`)
    }
    if (newEndDate.isSame(endDateTwo)) {
      newEndDate = endDateOne.add(2, `weeks`).endOf(`week`)
    }
    setStartDateOne(newStartDate)
    setEndDateOne(newEndDate)
    setFormattedDate(newStartDate, newEndDate, setDateOne)
  }

  const prevTwo = () => {
    let newStartDate = startDateTwo.subtract(1, `weeks`).startOf(`week`)
    let newEndDate = endDateTwo.subtract(1, `weeks`).endOf(`week`)

    if (newStartDate.isSame(startDateOne)) {
      newStartDate = startDateTwo.subtract(2, `weeks`).startOf(`week`)
    }
    if (newEndDate.isSame(endDateOne)) {
      newEndDate = endDateTwo.subtract(2, `weeks`).endOf(`week`)
    }
    setStartDateTwo(newStartDate)
    setEndDateTwo(newEndDate)
    setFormattedDate(newStartDate, newEndDate, setDateTwo)
  }

  const nextTwo = () => {
    let newStartDate = startDateTwo.add(1, `weeks`).startOf(`week`)
    let newEndDate = endDateTwo.add(1, `weeks`).endOf(`week`)

    if (newStartDate.isSame(startDateOne)) {
      newStartDate = startDateTwo.add(2, `weeks`).startOf(`week`)
    }
    if (newEndDate.isSame(endDateOne)) {
      newEndDate = endDateTwo.add(2, `weeks`).endOf(`week`)
    }
    setStartDateTwo(newStartDate)
    setEndDateTwo(newEndDate)
    setFormattedDate(newStartDate, newEndDate, setDateTwo)
  }

  return (
    <div>
      <StyledHeading>
        Selecteer de periodes die u met elkaar wilt vergelijken
      </StyledHeading>
      <Grid container spacing={0.5}>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2">Periode 1</Typography>
          <Navigator
            date={dateOne}
            next={endDateOne.isAfter(dayjs()) ? null : nextOne}
            prev={prevOne}
            flexStart
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2">Periode 2</Typography>
          <Navigator
            date={dateTwo}
            next={endDateTwo.isAfter(dayjs()) ? null : nextTwo}
            prev={prevTwo}
            flexStart
          />
        </Grid>
      </Grid>
      <ComparisonChart
        patientId={patientId}
        startDate={startDateOne.toDate()}
        endDate={endDateOne.toDate()}
        startDateAlt={startDateTwo.toDate()}
        endDateAlt={endDateTwo.toDate()}
      />
    </div>
  )
}

export default Comparison
