import { useEffect, ComponentType } from 'react'
import { useAuth0 } from '../services/auth'
import { RouteComponentProps } from '@reach/router'

type PrivateRouteProps = {
  component: any
} & RouteComponentProps

const PrivateRoute: ComponentType<PrivateRouteProps> = ({
  component: Component,
  path,
  ...rest
}) => {
  const { authLoading, isAuthenticated, loginWithRedirect } = useAuth0()

  useEffect(() => {
    if (authLoading || isAuthenticated) {
      return
    }
    const loginAsync = async () => {
      await loginWithRedirect({
        appState: { targetUrl: window.location.pathname },
      })
    }
    loginAsync()
  }, [authLoading, isAuthenticated, loginWithRedirect, path])

  return isAuthenticated === true ? <Component {...rest} /> : null
}
export default PrivateRoute
