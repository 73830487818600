import React, { useState } from 'react'
import { Box, Tab, Tabs, Typography } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { useLocation, WindowLocation } from '@reach/router'
import styled from '@emotion/styled'
import { deepPurple, grey } from '@mui/material/colors'

import Navigator from '@/components/navigator'
import StressOverTime from '@/components/stressOverTime'
import StressPerDayOfTheWeek from '@/components/stressPerDayOfTheWeek'
import StressPerHour from '@/components/stressPerHour'
import InterventionsChart from '@/components/interventionsChart'
import Comparison from '@/components/comparison'

const StyledTab = styled(Tab)(() => ({
  textTransform: `uppercase`,
  fontWeight: 700,
  border: `1px solid ${grey[300]}`,
  borderTopLeftRadius: `10px`,
  borderTopRightRadius: `10px`,
  minWidth: `calc(100% / 3)`,

  '&.Mui-selected': {
    color: grey[100],
    backgroundColor: deepPurple[300],
  },
}))

interface InsightProps {
  userId: string
  location: any
}

dayjs.Ls.en.weekStart = 1
const Insight = ({ userId }: InsightProps) => {
  const [value, setValue] = useState(0)
  const [startDate, setStartDate] = useState(
    dayjs().subtract(8, `weeks`).startOf(`week`),
  )
  const [endDate, setEndDate] = useState(dayjs().endOf(`week`))
  const [date, setDate] = useState(
    `${startDate.format(`D MMMM YYYY`)} - ${endDate.format(`D MMMM YYYY`)}`,
  )
  const {
    state: { userName = `` },
  } = useLocation() as WindowLocation<{
    userName: string
  }>

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  function TabPanel(props: any) {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    )
  }

  const setFormattedDate = (startDate: Dayjs, endDate: Dayjs) => {
    setDate(
      `${startDate.format(`D MMMM YYYY`)} - ${endDate.format(`D MMMM YYYY`)}`,
    )
  }

  const prev = () => {
    const newStartDate = startDate.subtract(1, `weeks`).startOf(`week`)
    const newEndDate = endDate.subtract(1, `weeks`).endOf(`week`)
    setStartDate(newStartDate)
    setEndDate(newEndDate)
    setFormattedDate(newStartDate, newEndDate)
  }

  const next = () => {
    const newStartDate = startDate.add(1, `weeks`).startOf(`week`)
    const newEndDate = endDate.add(1, `weeks`).endOf(`week`)
    setStartDate(newStartDate)
    setEndDate(newEndDate)
    setFormattedDate(newStartDate, newEndDate)
  }

  return (
    <div>
      <Typography variant="h6">{userName}</Typography>
      <Navigator
        date={date}
        next={endDate.isAfter(dayjs()) ? null : next}
        prev={prev}
      />
      <Box sx={{ borderBottom: 1, borderColor: `divider` }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <StyledTab label="Spanning Over Tijd" />
          <StyledTab label="Interventies" />
          <StyledTab label="Vergelijking periodes" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <StressOverTime
          patientId={userId}
          startDate={startDate.toDate()}
          endDate={endDate.toDate()}
        />
        <StressPerDayOfTheWeek
          patientId={userId}
          startDate={startDate.toDate()}
        />
        <StressPerHour patientId={userId} startDate={startDate.toDate()} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <InterventionsChart patientId={userId} startDate={startDate.toDate()} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Comparison
          patientId={userId}
          initialStartDate={startDate}
          initialEndDate={endDate}
        />
      </TabPanel>
    </div>
  )
}

export default Insight
