import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
} from 'chart.js'
import { Grid, Typography } from '@mui/material'
import { Bar } from 'react-chartjs-2'
import { useFetchStressPerHour } from '@/hooks/api'
import Loader from './loader'
import ErrorMessage from './errorMessage'
import { findStressLevels } from '@/utils/helpers'

ChartJS.register(CategoryScale, LinearScale, BarElement)

interface StressPerHourProps {
  patientId: string
  startDate: Date
}

const StressPerHour = ({ patientId, startDate }: StressPerHourProps) => {
  const {
    loading,
    data: results,
    error,
  } = useFetchStressPerHour(patientId, startDate)
  const legend = results?.legend || {}
  const ranges = results?.ranges || {}
  //   const stressPoints = results?.items || []

  const options = {
    responsive: true,
    interaction: {
      mode: `index` as const,
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        display: true,
        text: `Spanningsniveau op moment van de dag`,
        font: { size: 16, weight: `bold` },
        padding: 20,
      },
    },
    scales: {
      y: {
        stacked: true,
        grace: 10,
        max: 100,
        min: 0,
        ticks: {
          callback: function (value: any): string {
            return ((value / 100) * 100).toFixed(0) + `%` // convert it to percentage
          },
        },
      },
      x: {
        stacked: true,
        grace: 0,
        max: Object.keys(legend).length,
        min: 0,
        grid: {
          display: false,
        },
      },
    },
  }

  const chartData = {
    labels: Object.values(legend).map((hourlySpan) => hourlySpan.join(`-`)),
    datasets: [
      {
        label: `stress 1`,
        backgroundColor: `rgba(95, 218, 101, 0.7)`,
        data: findStressLevels(ranges, 1),
      },
      {
        label: `stress 2-3`,
        backgroundColor: `rgba(255, 225, 70, 0.7)`,
        data: findStressLevels(ranges, 2, 3),
      },
      {
        label: `stress 4-5`,
        backgroundColor: `rgba(255, 147, 48, 0.7)`,
        data: findStressLevels(ranges, 4, 5),
      },
      {
        label: `stress 6`,
        backgroundColor: `rgba(235, 29, 29, 0.7)`,
        data: findStressLevels(ranges, 6),
      },
    ],
  }

  if (loading) {
    return <Loader size={`sm`} />
  }

  if (error) {
    return (
      <ErrorMessage
        message={
          (error as string) || `There was an error while fetching chart data`
        }
      />
    )
  }

  return (
    <Grid container spacing={0.5}>
      <Grid item xs={12} sm={3}>
        <Typography sx={{ marginTop: 8 }}>
          Aantal rapportages in relatieve waarde
        </Typography>
      </Grid>
      <Grid item xs={12} sm={9}>
        <Bar options={options} data={chartData} />
      </Grid>
    </Grid>
  )
}

export default StressPerHour
