import { useState } from 'react'
import {
  List,
  Typography,
  Box,
  ListItemIcon,
  ListItemText,
  Collapse,
  ListItemButton,
  ListSubheader,
} from '@mui/material'
import { red, orange, yellow, green } from '@mui/material/colors'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import dayjs, { Dayjs } from 'dayjs'
import styled from '@emotion/styled'

import { useFetchStressLevelsOneDayDetails } from '@/hooks/api'
import { truncateString } from '@/utils/helpers'
import Loader from './loader'
import ErrorMessage from './errorMessage'

const ColorDigitIcon = styled(Typography)(() => ({
  borderRadius: `50%`,
  color: `#fff`,
  width: 25,
  height: 25,
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
}))

const SectionTitle = styled(Typography)(() => ({
  marginLeft: `16px`,
}))

interface DayViewDetailsProps {
  patientId: string
  selectedDate: Dayjs
}

const DayViewDetails = ({
  patientId,
  selectedDate,
}: DayViewDetailsProps): JSX.Element => {
  const [dropdownsState, setDropdownsState] = useState<{
    [key: number]: boolean
  }>({})
  const { loading, data, error } = useFetchStressLevelsOneDayDetails(
    patientId,
    selectedDate.startOf(`day`).toDate(),
    selectedDate.endOf(`day`).toDate(),
    [selectedDate],
  )
  const fetchedMeasurements = data || []
  const stressLevelsColors: { [key: number]: string } = {
    1: green[400],
    2: yellow[600],
    3: yellow[600],
    4: orange[400],
    5: orange[400],
    6: red[400],
  }

  function onToggleMore(index: number) {
    setDropdownsState({
      [index]: !dropdownsState[index],
    })
  }

  const resultsList = fetchedMeasurements?.map((result, index) => {
    const interventionsList = result.interventions.length
      ? `Interventions: ${result.interventions.join(`, `)}`
      : ``

    const observationsList = result.observations.length
      ? `Observations: ${result.observations.join(`, `)}`
      : ``
    const secondaryText = `${interventionsList} ${observationsList} ${
      result.note ? result.note : ``
    }`.trim()

    return (
      <Box key={index}>
        <ListItemButton
          onClick={() => (secondaryText.length ? onToggleMore(index) : null)}
        >
          <ListItemIcon>
            <ColorDigitIcon
              sx={{ backgroundColor: stressLevelsColors[result.stressLevel] }}
            >
              {result.stressLevel}
            </ColorDigitIcon>
          </ListItemIcon>
          <ListItemText
            primary={dayjs(result.timestamp)
              .format(`D MMMM YYYY HH:mm`)
              .toString()}
            secondary={
              secondaryText.length ? truncateString(secondaryText, 54) : null
            }
          />
          {!!secondaryText.length &&
            (dropdownsState[index] ? <ExpandLess /> : <ExpandMore />)}
        </ListItemButton>
        <Collapse
          in={dropdownsState[index] || false}
          timeout="auto"
          unmountOnExit
        >
          {!!result.interventions.length && (
            <List
              component="div"
              subheader={
                <ListSubheader component="div">Interventions</ListSubheader>
              }
            >
              {result.interventions.map((item) => (
                <ListItemText
                  key={item}
                  primary={item}
                  primaryTypographyProps={{
                    fontSize: 14,
                    fontWeight: `medium`,
                    paddingLeft: 3,
                  }}
                />
              ))}
            </List>
          )}
          {!!result.observations.length && (
            <List
              component="div"
              subheader={
                <ListSubheader component="div">Observations</ListSubheader>
              }
            >
              {result.observations.map((item) => (
                <ListItemText
                  key={item}
                  primary={item}
                  primaryTypographyProps={{
                    fontSize: 14,
                    fontWeight: `medium`,
                    paddingLeft: 3,
                  }}
                />
              ))}
            </List>
          )}
          {!!result.note && (
            <List
              component="div"
              subheader={<ListSubheader component="div">Note</ListSubheader>}
            >
              <SectionTitle>{result.note}</SectionTitle>
            </List>
          )}
        </Collapse>
      </Box>
    )
  })

  if (loading) {
    return <Loader size={`sm`} />
  }

  if (error) {
    return (
      <ErrorMessage
        message={
          (error as string) ||
          `There was an error while fetching day's overview`
        }
      />
    )
  }

  return (
    <Box>
      {resultsList.length ? (
        <List dense={true}>{resultsList}</List>
      ) : (
        <SectionTitle sx={{ marginTop: 3 }}>
          Geen gegevens om te tonen
        </SectionTitle>
      )}
    </Box>
  )
}

export default DayViewDetails
