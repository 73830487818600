import React from 'react'
import styled from '@emotion/styled'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

interface NavigatorProps {
  date: string
  prev: () => void
  next: (() => void) | null
  loading?: boolean
  fullWidth?: boolean
  flexStart?: boolean
  children?: React.ReactNode
}

const Navigator = ({
  date,
  prev,
  next,
  loading,
  fullWidth = false,
  flexStart = false,
  children,
}: NavigatorProps) => {
  const HiddenIconButton = styled(IconButton)(() => ({
    opacity: 0,
    pointerEvents: `none`,
  }))

  const DataDisplay = styled.div(() => ({
    lineHeight: `72px`,
    textAlign: `center`,
    width: fullWidth ? `100%` : `auto`,
  }))

  const NavigatorControls = styled.div(() => ({
    width: `100%`,
    display: `flex`,
    alignItems: `center`,
    justifyContent: flexStart ? `flex-start` : `center`,
  }))

  return (
    <div>
      {children}
      <NavigatorControls>
        {!prev ? (
          <HiddenIconButton>
            <ChevronLeftIcon />
          </HiddenIconButton>
        ) : (
          <IconButton onClick={prev} disabled={loading}>
            <ChevronLeftIcon />
          </IconButton>
        )}
        <DataDisplay>{<div>{date}</div>}</DataDisplay>
        {!next ? (
          <HiddenIconButton>
            <ChevronLeftIcon />
          </HiddenIconButton>
        ) : (
          <IconButton onClick={next} disabled={loading}>
            <ChevronRightIcon />
          </IconButton>
        )}
      </NavigatorControls>
    </div>
  )
}
export default Navigator
