import { useState } from 'react'
import {
  List,
  Typography,
  Box,
  ListItemText,
  Collapse,
  ListItemButton,
} from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Dayjs } from 'dayjs'
import styled from '@emotion/styled'

import { useFetchReports } from '@/hooks/api'

import Loader from './loader'
import ErrorMessage from './errorMessage'

const SectionTitle = styled(Typography)(() => ({
  marginLeft: `16px`,
}))

interface ReportDetailsProps {
  patientId: string
  selectedDate: Dayjs
}

const ReportDetails = ({
  patientId,
  selectedDate,
}: ReportDetailsProps): JSX.Element => {
  const [dropdownsState, setDropdownsState] = useState<{
    [key: number]: boolean
  }>({})
  const { loading, data, error } = useFetchReports(
    patientId,
    selectedDate.startOf(`day`).toDate(),
    [selectedDate],
  )

  const fetchedReports = data?.items || []

  function onToggleMore(index: number) {
    setDropdownsState({
      [index]: !dropdownsState[index],
    })
  }

  const resultsList = fetchedReports?.map((result, index) => {
    return (
      <Box key={index}>
        <ListItemButton onClick={() => onToggleMore(index)}>
          <ListItemText
            primary={`${result?.report?.type} | ${result?.report?.topic} `}
          />
          {!!result?.report?.content &&
            (dropdownsState[index] ? <ExpandLess /> : <ExpandMore />)}
        </ListItemButton>
        <Collapse
          in={dropdownsState[index] || false}
          timeout="auto"
          unmountOnExit
        >
          <List component="div">
            <ListItemText
              key={result?.report?.type}
              primary={result?.report?.content}
              primaryTypographyProps={{
                fontSize: 14,
                fontWeight: `medium`,
                paddingLeft: 3,
              }}
            />
          </List>
        </Collapse>
      </Box>
    )
  })

  if (loading) {
    return <Loader size={`sm`} />
  }

  if (error) {
    return (
      <ErrorMessage
        message={
          (error as string) || `There was an error while fetching reports`
        }
      />
    )
  }

  return (
    <Box>
      <SectionTitle>Rapportages van geselecteerde dag</SectionTitle>
      {resultsList.length ? (
        <List dense={true}>{resultsList}</List>
      ) : (
        <SectionTitle sx={{ marginTop: 3 }}>
          Geen verslag om te laten zien
        </SectionTitle>
      )}
    </Box>
  )
}

export default ReportDetails
