import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { deepPurple } from '@mui/material/colors'

import { useFetchInterventionsCount } from '@/hooks/api'
import Loader from './loader'
import ErrorMessage from './errorMessage'
import { alpha } from '@mui/material'

ChartJS.register(CategoryScale, LinearScale, BarElement)

interface InterventionsChartProps {
  patientId: string
  startDate: Date
}

const InterventionsChart = ({
  patientId,
  startDate,
}: InterventionsChartProps) => {
  const {
    loading,
    data: results,
    error,
  } = useFetchInterventionsCount(patientId, startDate)
  const interventions = results || []

  const options = {
    indexAxis: `y` as const,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        display: true,
        text: `Interventies`,
        font: { size: 16, weight: `bold` },
        padding: 20,
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
      },
      x: {
        grace: 1,
      },
    },
  }

  const chartData = {
    labels: interventions.map((intervention) => intervention.name),
    datasets: [
      {
        backgroundColor: alpha(deepPurple[400], 0.65),
        data: interventions.map((intervention) => intervention.count),
      },
    ],
  }

  if (loading) {
    return <Loader size={`sm`} />
  }

  if (error) {
    return (
      <ErrorMessage
        message={
          (error as string) || `There was an error while fetching chart data`
        }
      />
    )
  }

  return <Bar options={options} data={chartData} />
}

export default InterventionsChart
